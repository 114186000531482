const moment = require('moment');

const icelandicOrder = [
    '',
    'A',
    'Á',
    'B',
    'C',
    'D',
    'Ð',
    'E',
    'É',
    'F',
    'G',
    'H',
    'I',
    'Í',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'Ó',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'Ú',
    'V',
    'W',
    'X',
    'Y',
    'Ý',
    'Z',
    'Þ',
    'Æ',
    'Ö',
];

const icelandicOrderMap = icelandicOrder.reduce((map, char, index) => {
    map[char] = index;
    return map;
}, {});

const getCharOrder = (char) => {
    return icelandicOrderMap[char.toUpperCase()] || char.charCodeAt(0);
};

const compareIcelandicStrings = (a, b) => {
    const aChars = a.split('');
    const bChars = b.split('');

    for (let i = 0; i < Math.min(aChars.length, bChars.length); i++) {
        const aOrder = getCharOrder(aChars[i]);
        const bOrder = getCharOrder(bChars[i]);

        if (aOrder !== bOrder) {
            return aOrder - bOrder;
        }
    }

    return aChars.length - bChars.length;
};

module.exports = {
    DEFAULT_EVENT_ICON: '/img/default_event.png',
    DEFAULT_EVENT_NAME: 'Reevent',

    REPEATING_EVENT: 0,
    SINGLE_EVENT: 1,

    dayNumberToDayName: function (number) {
        switch (number) {
            case 0:
                return 'Sunday';
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            case 7:
                return 'Sunday';
            default:
                throw new Error('Invalid day number ' + number);
        }
    },

    sortByName: function (dataArray) {
        return [...dataArray].sort((a, b) =>
            compareIcelandicStrings(a.name, b.name)
        );
    },

    sortParticipantsByAttendance: function (participants) {
        const LEFT_FIRST = -1;
        const RIGHT_FIRST = 1;
        return [...participants].sort((left, right) => {
            if (left.confirmed === true) {
                if (right.confirmed !== true) return LEFT_FIRST;
                // Fall through to name comparison (both a and b are attending).
            } else if (left.confirmed === false) {
                if (right.confirmed === true) return RIGHT_FIRST;
                if (right.confirmed !== false) return LEFT_FIRST;
                // Fall through to name comparison (neither a nor b are attending).
            } else {
                // A is neither true nor false, so it must be undecided (null).
                if (right.confirmed !== null) return RIGHT_FIRST;
                // Fall through to name comparison (neither a nor b have responded).
            }
            return compareIcelandicStrings(left.name, right.name);
        });
    },

    sortByTime: function (dataArray) {
        const LEFT_FIRST = -1;
        const RIGHT_FIRST = 1;
        return [...dataArray].sort((left, right) => {
            if (left.confirmed === null) {
                if (right.confirmed !== null) {
                    return RIGHT_FIRST;
                }
            } else {
                if (right.confirmed === null) {
                    return LEFT_FIRST;
                }
            }

            if (left.timestamp !== right.timestamp) {
                const dateA = new Date(left.timestamp);
                const dateB = new Date(right.timestamp);
                return dateB - dateA;
            }

            return compareIcelandicStrings(left.name, right.name);
        });
    },

    getAttendanceCount: function (participants) {
        return participants.reduce((accumulator, participant) => {
            if (
                participant.confirmed === true || //TODO - some inconsistency in data usage. Should be 1/0
                participant.confirmed === 1 ||
                participant.confirmed === '1'
            ) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);
    },

    getAttendancePercentage: function (attendance, minParticipantCount) {
        let attendancePercentage = (attendance / minParticipantCount) * 100;
        return attendancePercentage > 100 ? 100 : attendancePercentage;
    },

    // Convert Icelandic SSN to birthday and return as YYYY-MM-DD.
    obtainBirthdateFromSsn: function (ssn) {
        if (!ssn || ssn.length !== 10 || ssn === '0000000000') {
            return null;
        }
        const birthdate =
            (ssn[9] === '0' ? '20' : '19') +
            ssn[4] +
            ssn[5] +
            '-' +
            ssn[2] +
            ssn[3] +
            '-' +
            ssn[0] +
            ssn[1];
        return birthdate;
    },

    saveLastCreatedUuid: function (uuid) {
        localStorage.setItem('lastCreatedUuid', uuid);
    },

    ensureNonNegativeAndForwardTo: function (value, recipientFunction) {
        // Ensure the input is a non-negative integer
        let newValue = value === '' ? 0 : parseInt(value, 10);
        newValue = Math.max(0, newValue);
        // Pass the sanitized input to the function supplied.
        recipientFunction(isNaN(newValue) ? 0 : newValue);
    },

    isTestUser: function (userEmail) {
        // The date and time pickers support showing a confirmation button,
        // which are annoying for humans, but help a lot in testing
        // (prevents the test from having to click a random field to get the
        // dropdowns to disappear after text input), so we set these buttons
        // to show only for our test users.
        return (
            userEmail === 'testuser@example.com' ||
            userEmail === 'testuser_data@example.com'
        );
    },

    dateMaybeWithYear: function (date) {
        const momentDate = moment(date);
        if (momentDate.year() === moment().year())
            return momentDate.format('D. MMM');
        else return momentDate.format('D. MMM YYYY');
    },
};
