import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { fetchAllFutureEventsByOwner } from '../api/fetchAllEvents.js';
import { fetchAllParticipantsByOwner } from '../api/fetchParticipants.js';
import EventItemAdmin from '../components/EventItemAdmin.js';
import ParticipantOverview from '../components/ParticipantOverview.js';

moment().locale('is');

function Dashboard() {
    const auth = useAuth();
    const [events, setEvents] = useState(null);
    const [participants, setParticipants] = useState(null);

    // Get all events
    const getAllEvents = async () => {
        const eventData = await fetchAllFutureEventsByOwner(
            auth.currentUser.user_id
        );
        setEvents(eventData);
    };

    useEffect(() => {
        getAllEvents();
    }, []);

    const getParticipants = async () => {
        const participantsData = await fetchAllParticipantsByOwner(
            auth.currentUser.user_id
        );
        setParticipants(participantsData);
    };

    useEffect(() => {
        getParticipants();
    }, []);

    const navigate = useNavigate();

    const handleAddEvent = () => {
        navigate('/admin/events/create');
    };

    const handleAddParticipant = () => {
        navigate('/admin/participants/');
    };

    return (
        <>
            <div>
                <h1 id="pageDashboard">Dashboard</h1> <hr />
                <h2>Events</h2>
                <div className="block">
                    {events ? (
                        <ul className="simple-list">
                            {events.map((event) => {
                                return (
                                    <EventItemAdmin
                                        key={event.event_id}
                                        event={event}
                                        refreshEvents={getAllEvents}
                                    />
                                );
                            })}
                        </ul>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <button
                    id="buttonCreateNewEvent"
                    className="button is-primary is-small"
                    onClick={handleAddEvent}
                >
                    + Create new
                </button>
                <br />
                <br />
                <h2>Participants</h2>
                <div className="block limit-height">
                    <ul className="simple-list">
                        {participants && (
                            <>
                                {participants.map((participant) => (
                                    <ParticipantOverview
                                        key={participant.participant_id}
                                        participant={participant}
                                        refreshParticipants={getParticipants}
                                    />
                                ))}
                            </>
                        )}
                    </ul>
                </div>
                <br />
                <button
                    className="button is-primary is-small"
                    onClick={handleAddParticipant}
                >
                    + Add participant
                </button>
            </div>
        </>
    );
}

export default Dashboard;
