import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

import utils from '../utils/utils.js';

moment().locale('is');

export const createEvent = async (
    name,
    gathering_id,
    short_description,
    long_description,
    is_single_event,
    weekday,
    time,
    single_date,
    location,
    min_participants,
    max_participants,
    owner_id
) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/event';
    try {
        const response = await axios.post(
            session_url,
            {
                name: name,
                short_description: short_description,
                long_description: long_description,
                type: is_single_event
                    ? utils.SINGLE_EVENT
                    : utils.REPEATING_EVENT,
                weekday: is_single_event ? undefined : weekday,
                time_of_day: is_single_event ? undefined : time.format('HH:mm'),
                single_date: is_single_event ? single_date : undefined,
                active: '1',
                venue_id: location,
                min_participants: min_participants,
                max_participants: max_participants,
                owner_id: owner_id,
                gathering_id: gathering_id,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );

        if (response.status === 201) {
            utils.saveLastCreatedUuid(response.data.event_id);
        }

        return response;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
