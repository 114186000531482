import axios from 'axios';

export const updateAttendanceEmail = async (
    participant_id,
    session_id,
    email_response
) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL +
        `/attendance-email-response/?participant_id=${participant_id}&session_id=${session_id}&response=${email_response}`;
    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
