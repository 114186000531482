import { DatePicker, Input, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/is';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { deleteEvent } from '../api/deleteEvent.js';
import { runCron } from '../api/runCron.js';
import { updateSession } from '../api/updateSession.js';
import { showToast, updateToast } from '../utils/toastUtils.js';
import utils from '../utils/utils';
import Venue from './Venue';
import testUtils from '../utils/testUtils.js';

moment().locale('is');

export default function EventItemAdmin({ event, refreshEvents }) {
    const auth = useAuth();

    // Note: It is possible for an Event to have no Session, if the Session is
    // in the past and the cron job has not created a new one. In those cases we
    // use null or fall back to defaults from the event, when available. The
    // only exception is the sessionId, because it is used as an identifier on
    // the page for tests to pick up, and we want it to be something human
    // searchable (for easier debugging if tests fail).
    const session = testUtils.simulatingNullSession(event.event_id)
        ? null
        : event.sessions[0];
    const sessionId = session ? session.session_id : 'missing-session';
    const sessionVenueId = session ? session.venue_id : event.venue_id;
    const sessionVenueName = session ? session.venue.name : event.venue.name;
    const sessionAdminAlert = session ? session.admin_alert : null;
    const sessionTimestamp = session ? session.timestamp : null;

    const navigate = useNavigate();
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] =
        useState(false);
    const [day_moment, setDayMoment] = useState(null);
    const [venue_id, setNextSessionVenueId] = useState(sessionVenueId);
    const [adminAlert, setAdminAlert] = useState(sessionAdminAlert);
    const [active, setActive] = useState(true);

    const handleEditEvent = (event_id) => {
        navigate('/admin/events/' + event_id);
    };

    const handleUpdateSession = async (e) => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdSaveSession',
        });
        try {
            const timestamp = day_moment ? day_moment.toISOString() : null;

            await updateSession(
                sessionId,
                timestamp,
                venue_id,
                adminAlert,
                active
            );
            updateToast(toastId, auth.currentUser.email, {
                render: 'Session saved',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            refreshEvents();
            setOpen(false);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Failed to save session',
                type: 'error',
                isLoading: false,
                autoClose: 1500,
            });
        }
    };

    const commitDeleteEvent = async (event_id) => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdDeleteEvent',
        });

        try {
            await deleteEvent(event_id);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Event deleted',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            refreshEvents();
            setIsShowingDeleteConfirmation(false);
        } catch (err) {
            console.error(err);
        }
    };

    // Controls whether to show the 'Session unavailable' message and Edit
    // Session button.
    const sessionUnavailable =
        !session || (!sessionTimestamp && event.type !== utils.SINGLE_EVENT);

    let nameLink = (
        <span>
            <a
                className="link"
                // eslint-disable-next-line react/no-unknown-property
                session={sessionId}
                id={'link-event-' + event.event_id}
                onClick={() => handleEditEvent(event.event_id)}
            >
                {event.name}
            </a>
            <span>
                {' '}
                {event.short_description && (
                    <Tooltip title={event.short_description}>
                        <img
                            src="/img/shout.svg"
                            alt="Short description"
                            style={{ height: 20 }}
                        />
                    </Tooltip>
                )}
            </span>
        </span>
    );

    let description = (
        <span className="light">
            {event.type === utils.SINGLE_EVENT
                ? 'Once'
                : 'Every ' +
                  utils.dayNumberToDayName(event.weekday || 0) +
                  ' at ' +
                  moment(event.time_of_day, 'HH:mm:ss', true).format('HH:mm')}
        </span>
    );

    // Modal specific
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showModal = () => {
        setOpen(true);
        if (sessionTimestamp) {
            setDayMoment(dayjs(sessionTimestamp));
        }
    };
    const handleOk = () => {
        handleUpdateSession();
        setConfirmLoading(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const handleVenueIdChange = (newVenueId) => {
        setNextSessionVenueId(newVenueId);
    };

    const handleAdminAlertChange = (e) => {
        setAdminAlert(e.target.value);
    };

    return (
        <>
            <div
                className="columns"
                style={{
                    alignItems: 'center',
                }}
            >
                <div className="column is-3 is-vcentered">
                    <li
                        className={
                            event.type === utils.SINGLE_EVENT ? 'single' : ''
                        }
                    >
                        {nameLink}
                    </li>
                </div>
                <div className="column is-narrow">
                    {' '}
                    <Link
                        to={
                            window.location.origin +
                            '/session/event/' +
                            event.event_id
                        }
                    >
                        <img
                            src="/img/view.svg"
                            alt="Edit event template"
                            style={{ paddingTop: 9 }}
                        />
                    </Link>
                </div>
                <div className="column has-text-centered">
                    {' '}
                    {sessionTimestamp && (
                        <div className="sublist">
                            {moment(sessionTimestamp).format(
                                'Do MMM YYYY, HH:mm'
                            )}
                            <br />
                            {sessionVenueName}{' '}
                            <span>
                                {sessionAdminAlert && (
                                    <Tooltip title={sessionAdminAlert}>
                                        <img
                                            src="/img/shout.svg"
                                            alt="Admin alert"
                                            style={{ height: 20 }}
                                        />
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                    )}
                    {sessionUnavailable && (
                        <div className="sublist" style={{ opacity: 0.2 }}>
                            Next session currently not available -{' '}
                            <span onClick={runCron}>Create</span>
                            {'  '}
                        </div>
                    )}
                </div>
                <div className="column is-narrow">
                    {!sessionUnavailable && (
                        <span
                            id={'editSession-' + sessionId}
                            onClick={showModal}
                        >
                            <img
                                src="/img/edit.svg"
                                alt="Edit session"
                                style={{ paddingTop: 9 }}
                            />
                        </span>
                    )}
                </div>
                <div className="column is-3 has-text-centered">
                    {description}
                </div>
                <div className="column is-narrow">
                    <a
                        className="link"
                        // eslint-disable-next-line react/no-unknown-property
                        session={sessionId}
                        id={'link-event-' + event.event_id}
                        onClick={() => handleEditEvent(event.event_id)}
                    >
                        <img
                            src="/img/settings.svg"
                            alt="Edit event template"
                            style={{ paddingTop: 9 }}
                        />
                    </a>
                </div>
            </div>

            {isShowingDeleteConfirmation && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Delete event: {event.name}?
                            </p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={() =>
                                    setIsShowingDeleteConfirmation(false)
                                }
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Are you sure? This can not be undone!
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="confirmDeleteEvent"
                                className="button is-danger"
                                onClick={() =>
                                    commitDeleteEvent(event.event_id)
                                }
                            >
                                Confirm delete
                            </button>
                            <button
                                id="cancelDeleteEvent"
                                className="button"
                                onClick={() =>
                                    setIsShowingDeleteConfirmation(
                                        !isShowingDeleteConfirmation
                                    )
                                }
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
            <Modal
                title={'Change next session only for: ' + event.name}
                open={open}
                onOk={handleUpdateSession}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <h4>
                        <strong>Time</strong>
                    </h4>
                    <DatePicker
                        showTime
                        format={'YYYY/MM/DD HH:mm'}
                        size={'large'}
                        onChange={(value) => {
                            setDayMoment(value);
                        }}
                        value={day_moment}
                        needConfirm={utils.isTestUser(auth.currentUser.email)}
                    />

                    <Venue
                        venue_id={venue_id}
                        setVenueId={handleVenueIdChange}
                    />

                    <h3>
                        <strong>Admin alert</strong> -
                        <small>Appears at the top of the session</small>
                    </h3>
                    <Input
                        id="editAdminAlert"
                        value={adminAlert}
                        onChange={handleAdminAlertChange}
                    />
                </div>
            </Modal>
        </>
    );
}
