import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import {
    fetchAllEventsByOwner,
    fetchAllEventsByParticipants,
} from '../api/fetchAllEvents.js';
import { toggleAllowedAttendance } from '../api/participantEvents.js';
import currentUserUtils from '../utils/currentUserUtils.js';

export default function ParticipantAttendanceModal({
    participant_id,
    participant_name,
    participant_owner_id,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // Supplementary data.
    const [eventsByOwner, setEventsByOwner] = useState('');
    const [attendances, setAttendances] = useState('');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const refreshAttendances = async (owner_id) => {
        const eventsByOwner = await fetchAllEventsByOwner(owner_id);
        const eventsByParticipant = await fetchAllEventsByParticipants(
            currentUserUtils.getParticipantsAsArray()
        );

        let attendances = {};
        eventsByOwner.forEach((event) => {
            let active = false;
            eventsByParticipant.forEach((eventParticipant) => {
                if (event.event_id === eventParticipant.event_id) {
                    active = true;
                }
            });
            attendances[event.event_id] = active;
        });
        setEventsByOwner(eventsByOwner);
        setAttendances(attendances);
    };

    useEffect(() => {
        refreshAttendances(participant_owner_id);
    }, [participant_owner_id]);

    const handleEventParticipationChange = async (value) => {
        if (!value.target.id.startsWith('event-')) {
            throw new Error('Unknown event id format');
        }

        let eventId = value.target.id;
        eventId = eventId.slice(eventId.indexOf('-') + 1);

        await toggleAllowedAttendance(eventId, participant_id);
        await refreshAttendances(participant_owner_id);
    };

    return (
        <>
            <Button
                id="buttonViewParticipation"
                type="primary"
                key={'button-' + participant_id}
                onClick={showModal}
                style={{ marginTop: '8px' }}
            >
                View participation
            </Button>
            <Modal
                title={'Participation for ' + participant_name}
                key={'modal-' + participant_id}
                open={isModalOpen}
                onCancel={handleClose}
                footer={null} // No buttons needed.
            >
                <ul className="simple-list participation">
                    {eventsByOwner &&
                        eventsByOwner.map((event) => (
                            <li key={participant_id + '-' + event.event_id}>
                                <input
                                    id={'event-' + event.event_id}
                                    type="checkbox"
                                    name="participation"
                                    checked={attendances[event.event_id]}
                                    onChange={(value) => {
                                        handleEventParticipationChange(value);
                                    }}
                                    className="switch is-rounded is-success"
                                />
                                <label
                                    key={
                                        'label' +
                                        participant_id +
                                        '-' +
                                        event.event_id
                                    }
                                    htmlFor={'event-' + event.event_id}
                                >
                                    <span>{event.name}</span>
                                </label>
                                <br />
                            </li>
                        ))}
                </ul>
            </Modal>
        </>
    );
}
