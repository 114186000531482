module.exports = {
    getParticipantMap: function () {
        return JSON.parse(localStorage.getItem('currentParticipantMap')) || {};
    },

    getParticipantForOwner: function (ownerId) {
        const participantMap = this.getParticipantMap();
        return participantMap[ownerId];
    },

    getParticipantsAsArray: function () {
        const participantMap = this.getParticipantMap();
        return Object.values(participantMap);
    },

    maybeSaveParticipantForOwner: function (ownerId, participantId) {
        let currentParticipantId = this.getParticipantForOwner(ownerId);
        if (currentParticipantId != null) return;

        let map = this.getParticipantMap();
        map[ownerId] = participantId;
        localStorage.setItem('currentParticipantMap', JSON.stringify(map));
    },

    deleteParticipantForOwner: function (ownerId) {
        let participantMap = this.getParticipantMap();
        delete participantMap[ownerId];
        localStorage.setItem(
            'currentParticipantMap',
            JSON.stringify(participantMap)
        );
    },
};
