//import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fetchChats, sendChatMessage } from '../api/chat.js';
import { fetchEventActiveSession } from '../api/fetchEvent.js';
import { fetchParticipant } from '../api/fetchParticipant.js';
import { fetchSessionParticipants } from '../api/fetchParticipants.js';
import { fetchSession } from '../api/fetchSession.js';
import { updateAttendance } from '../api/participantEvents.js';
//import AttendanceChart from '../components/AttendanceChart';
import { fetchGathering } from '../api/fetchGathering.js';
import EventDetails from '../components/EventDetails';
import Participation from '../components/Participation.js';
import ProfileAvatar from '../components/ProfileAvatar.js';
import { Sponsors } from '../components/Sponsors';
import currentUserUtils from '../utils/currentUserUtils.js';
import { showToast } from '../utils/toastUtils.js';
import utils from '../utils/utils';

moment().locale('is');

function Session() {
    const [sessionData, setSession] = useState(null);
    const [author, setAuthor] = useState('');
    const [participant, setParticipant] = React.useState(null);
    const [participants, setParticipants] = useState(null);
    const [chats, setChats] = useState(null);
    const [chatCount, setChatCount] = useState(0);
    const [sort, setActiveSorting] = useState('attendance');
    const [attendance, setAttendanceCount] = useState(0);
    const [showGameOn, setShowGameOn] = useState(false);
    const [showMaxedOut, setShowMaxedOut] = useState(false);
    const [showMobileChat, setShowMobileChat] = useState(false);
    const [currentParticipantId, setCurrentParticipantId] = useState(null);
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [long_description, setLongDescription] = useState('');
    const [gatheringName, setGatheringName] = React.useState('');
    const [gatheringLogo, setGatheringLogo] = React.useState('');

    const { t } = useTranslation();
    //const [user, setUser] = useState([]);
    //const [profile, setProfile] = useState([]);

    let { id } = useParams();

    // Initialize session.
    const getSession = async (id) => {
        try {
            // This page can be accessed as /session/event/[event_id], which provides a fixed url for bookmarks. Then the
            // id passed in is an event id, which needs to be converted to a session_id.
            if (window.location.href.indexOf('/session/event/') > -1) {
                const res = await fetchEventActiveSession(id);
                id = res.active_session_id;
            }

            const res = await fetchSession(id);
            setSession(res);
            const currentParticipantId =
                currentUserUtils.getParticipantForOwner(res.event.owner_id);
            setCurrentParticipantId(currentParticipantId);

            // Uncomment to see Avatar on Session page.
            if (currentParticipantId != null)
                setParticipant(await fetchParticipant(currentParticipantId));

            setLongDescription(res.long_description); //seperate because of issue getting lenght if string is empty
            // Adds Gathering name
            if (res) {
                try {
                    const gatheringData = await fetchGathering(
                        res.event.gathering_id
                    );
                    setGatheringName(gatheringData.name);
                    setGatheringLogo(gatheringData.image_url);
                    setLongDescription(res.event.long_description);

                    document.title = gatheringData.name;
                } catch (exception) {
                    console.log(
                        'Error fetching gathering with id: ',
                        res.event.gathering_id,
                        exception.toString()
                    );
                }
            } else {
                setGatheringName(utils.DEFAULT_EVENT_NAME);
                setGatheringLogo(utils.DEFAULT_EVENT_ICON);
            }
            await getParticipants(res.session_id);
            await getChats(res.session_id);
        } catch (err) {
            console.error(err);
        }
    };

    const getParticipant = async (participant_id) => {
        if (!participant_id) {
            return;
        }
        try {
            const participant = await fetchParticipant(participant_id);
            let author = participant.name;
            let index = participant.name.indexOf(' ');
            if (index > -1) {
                author = participant.name.substring(0, index);
            }
            if (participant.nickname !== '') {
                author += ' / ' + participant.nickname;
            }

            setAuthor(author);
        } catch (exception) {
            console.log(
                'Error fetching participant with id: ',
                participant_id,
                exception.toString()
            );
        }
    };

    useEffect(() => {
        if (!participants) {
            return;
        }

        const count = utils.getAttendanceCount(participants);
        setShowGameOn(count >= sessionData.min_participants);
        setShowMaxedOut(count >= sessionData.max_participants);
        setAttendanceCount(count);

        getParticipant(currentParticipantId);
    }, [participants, currentParticipantId]);

    // Show/hide chat on mobile.
    const showChatMobile = () => {
        setShowMobileChat(!showMobileChat);
    };

    const sortParticipantsByName = () => {
        if (!Array.isArray(participants)) {
            return [];
        }

        setParticipants(utils.sortByName(participants));
        setActiveSorting('name');
    };

    const sortParticipantsByAttendance = () => {
        if (!Array.isArray(participants)) {
            return [];
        }

        setParticipants(utils.sortParticipantsByAttendance(participants));
        setActiveSorting('attendance');
    };

    const sortParticipantsByTime = () => {
        if (!Array.isArray(participants)) {
            return [];
        }

        setParticipants(utils.sortByTime(participants));
        setActiveSorting('time');
    };

    // Get session data.
    useEffect(() => {
        getSession(id);
    }, [id]);

    const getChats = async (session_id) => {
        const chats = await fetchChats(session_id);
        setChatCount(chats ? chats.length : 0);
        setChats(chats);
    };

    const postChat = async () => {
        let chat = document.getElementById('chat').value;
        let author = document.getElementById('author').value;

        if (chat.length === 0 || author.length === 0) {
            showToast(t('Chat message or name missing'), '', {
                toastId: 'toastIdChatInfoMissing',
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            });
            return;
        }

        await sendChatMessage(sessionData.session_id, author, chat)
            .then((res) => {
                document.getElementById('chat').value = '';
                document.getElementById('author').value = '';
                getChats(sessionData.session_id);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getParticipants = async (session_id) => {
        const participants = await fetchSessionParticipants(session_id);
        setParticipants(utils.sortParticipantsByAttendance(participants));
    };

    const userRespond = async (e) => {
        const count = utils.getAttendanceCount(participants);
        if (e.target.value === '1' && count >= sessionData.max_participants) {
            showToast(t('This event is already maxed out'), '', {
                toastId: 'toastIdEventMaxedOut',
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            });
            return;
        }

        const newParticipants = participants.map((participant) => {
            if (participant.attendance_id === e.target.name) {
                let currentParticipant =
                    currentUserUtils.getParticipantForOwner(
                        sessionData.event.owner_id
                    );
                if (currentParticipant == null) {
                    setCurrentParticipantId(participant.participant_id);
                }

                currentUserUtils.maybeSaveParticipantForOwner(
                    sessionData.event.owner_id,
                    participant.participant_id
                );

                // Remove the old key if any (it has been deprecated).
                localStorage.removeItem('currentParticipantId');

                return {
                    ...participant,
                    confirmed: e.target.value === '1',
                    timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                };
            }

            return participant;
        });

        try {
            await updateAttendance(
                e.target.name,
                e.target.value,
                currentUserUtils.getParticipantForOwner(
                    sessionData.event.owner_id
                )
            );
        } catch (err) {
            console.log(err);
        }
        setParticipants(newParticipants);
    };

    /* useEffect(() => {
         if (user.length >0) {
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json',
                        },
                    }
                )
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
        else {
            setProfile(null);
        }
    }, [user]); */

    /*  const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log("Login Failed:", error),
    }); */

    // log out function to log the user out of google and set the profile array to null
    /*   const logOut = () => {
        googleLogout();
        setProfile(null);
    }; */

    let attendancePercentage = utils.getAttendancePercentage(
        attendance,
        sessionData !== null ? sessionData.min_participants : 1
    );

    useEffect(() => {
        // Simulate fetching data
        const fetchData = async () => {
            const data = [
                {
                    timestamp: '2024-06-21T10:00:00.000Z',
                    type: 'attend',
                    attendanceCount: 5,
                },
                {
                    timestamp: '2024-06-21T11:00:00.000Z',
                    type: 'leave',
                    attendanceCount: 4,
                },
                {
                    timestamp: '2024-06-21T12:00:00.000Z',
                    type: 'attend',
                    attendanceCount: 6,
                },
                {
                    timestamp: '2024-06-21T13:00:00.000Z',
                    type: 'leave',
                    attendanceCount: 5,
                },
                // Add more data as needed
            ];
        };

        fetchData();
    }, []);

    return (
        <div className="App">
            <div
                className={`chatMobile ${showMobileChat ? 'active' : ''}`}
                onClick={showChatMobile}
            >
                {chatCount > 0 && <div className="chatCount">{chatCount}</div>}
                <img src="/img/chat-bubble.png" alt={t('Chat')} />
            </div>
            <div
                className={`chatMobile ${!showMobileChat ? 'active' : ''}`}
                onClick={showChatMobile}
            >
                <img src="/img/close.png" alt={t('Close')} />
            </div>
            <div
                className="container main"
                // eslint-disable-next-line react/no-unknown-property
                sessionid={sessionData ? sessionData.session_id : 'missing'}
            >
                <div className="columns main_inner">
                    <div className="column  left-area">
                        <div className="brand">
                            <div className="logo">
                                <div className="beta-tag">Beta</div>
                                {sessionData && (
                                    <img
                                        src={gatheringLogo}
                                        alt={gatheringName}
                                    />
                                )}
                            </div>
                            <div className="name">{gatheringName}</div>
                        </div>

                        {sessionData && (
                            <EventDetails
                                name={sessionData.event.name}
                                date={sessionData.timestamp}
                                time={moment(sessionData.timestamp).format(
                                    'HH:mm:ss'
                                )}
                                venue={sessionData.venue}
                                attendanceCount={attendance}
                                attendancePercentage={attendancePercentage}
                                showGameOn={showGameOn}
                                showMaxedOut={showMaxedOut}
                            />
                        )}
                        {sessionData && sessionData.admin_alert && (
                            <div className="alert" id="adminAlert">
                                {sessionData.admin_alert}
                            </div>
                        )}
                        <div
                            className={`chat ${showMobileChat ? 'active' : ''}`}
                        >
                            <div className="messages">
                                {chats && (
                                    <>
                                        {chats.map((chat) => (
                                            <div
                                                className="message"
                                                key={chat.chat_id}
                                            >
                                                <div className="avatar"></div>
                                                <div
                                                    className="text"
                                                    id={'chat-' + chat.chat_id}
                                                >
                                                    {chat.text}
                                                </div>
                                                <div className="author">
                                                    <span
                                                        className="name"
                                                        id={
                                                            'author-' +
                                                            chat.chat_id
                                                        }
                                                    >
                                                        {chat.author}
                                                    </span>{' '}
                                                    -{' '}
                                                    <span
                                                        className="time"
                                                        id={
                                                            'time-' +
                                                            chat.chat_id
                                                        }
                                                    >
                                                        {moment(
                                                            chat.timestamp
                                                        ).format(
                                                            'HH:mm - D. MMMM'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}

                                <div className="sendMessage">
                                    <div className="label">
                                        {t('Write your message')}
                                    </div>
                                    <div className="messageInput">
                                        <textarea
                                            id="chat"
                                            rows="4"
                                            placeholder={t(
                                                'Write your message'
                                            )}
                                        ></textarea>
                                        {/* profile && (
                                            <div className="name">
                                                {profile.name}
                                            </div>
                                        ) */}

                                        <input
                                            type="text"
                                            placeholder={t('Sender')}
                                            defaultValue={author}
                                            id="author"
                                            name="author"
                                        />

                                        <button
                                            className="button is-primary"
                                            onClick={postChat}
                                            id="send"
                                        >
                                            {t('Send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="sponsors desktop-only">
                                <Sponsors />
                            </div>
                        </div>
                        <div className="version">Zoolander 1.0</div>
                    </div>
                    <div className="column is-two-thirds right-area">
                        <div className="profile">
                            {participant && (
                                <div className="profile-menu">
                                    <ProfileAvatar
                                        participant={participant}
                                        newFunctionality={true}
                                    />
                                </div>
                            )}
                            {/* profile ? (
                                <>
                                    <div className="img">
                                        <img src={profile.picture} alt="user" />
                                    </div>
                                    <p>{profile.name} </p>

                                    <div onClick={logOut}> Útskrá</div>
                                </>
                            ) : (
                                <div onClick={() => login()}>Innskrá 🚀 </div>
                            ) */}
                        </div>
                        {long_description && long_description.length !== 0 && (
                            <>
                                <div className="description">
                                    <p>{long_description}</p>
                                </div>
                            </>
                        )}

                        {long_description && long_description.length > 249 && (
                            <>
                                {showMoreDescription
                                    ? long_description
                                    : `${long_description.substring(0, 250)}`}
                                <span
                                    className="btn"
                                    onClick={() =>
                                        setShowMoreDescription(
                                            !showMoreDescription
                                        )
                                    }
                                >
                                    {showMoreDescription
                                        ? ' ...minna'
                                        : ' ...meira'}
                                </span>
                            </>
                        )}
                        {/*  <div className="attendance-chart">
                            <AttendanceChart data={attendanceData} />
                        </div> */}

                        <div className="sort">
                            <span>{t('Sort by')}</span>
                            <span
                                id="sortByAttendance"
                                onClick={sortParticipantsByAttendance}
                                tabIndex="0"
                                className={
                                    sort === 'attendance' ? 'active' : ''
                                }
                            >
                                {t('Attendance')}
                            </span>
                            <span> | </span>
                            <span
                                id="sortByName"
                                onClick={sortParticipantsByName}
                                tabIndex="0"
                                className={sort === 'name' ? 'active' : ''}
                            >
                                {t('Alphabetically')}
                            </span>
                            <span> | </span>
                            <span
                                id="sortByTime"
                                onClick={sortParticipantsByTime}
                                tabIndex="0"
                                className={sort === 'time' ? 'active' : ''}
                            >
                                {t('Time')}
                            </span>
                        </div>
                        {participants && (
                            <div className="participation">
                                {participants.map((participant) => (
                                    <Participation
                                        key={participant.participant_id}
                                        participant={participant}
                                        onChange={userRespond}
                                        currentParticipantId={
                                            currentParticipantId
                                        }
                                        ownerId={sessionData.event.owner_id}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="sponsors mobile-only">
                        <Sponsors />
                    </div>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default Session;
