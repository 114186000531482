import moment from 'moment';
import 'moment/locale/is';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import AdminMenu from '../components/dashboard/AdminMenu.js';
import utils from '../utils/utils';

moment().locale('is');

function Admin() {
    const auth = useAuth();
    const navigate = useNavigate();

    const signOut = async () => {
        try {
            await auth.signOut();
            //  navigate('/login');
        } catch (err) {
            console.error('logout failed', err);
        }
    };

    return (
        <div className="App">
            <div className="container main">
                <div className="columns main_inner">
                    <div className="column adminLeft">
                        <div className="brand">
                            <div className="logo">
                                <img
                                    src={utils.DEFAULT_EVENT_ICON}
                                    alt="Reevent"
                                />
                            </div>
                            <div className="name">Reevent</div>
                        </div>
                        <br />
                        <h1>Admin</h1>
                        <AdminMenu />
                    </div>
                    <div className="column is-three-quarters right-area adminRight">
                        <div className="admin-info">
                            {auth.currentUser ? (
                                <p>Welcome, {auth.currentUser.name}!</p>
                            ) : (
                                <p>You are not logged in.</p>
                            )}
                            {auth.currentUser ? (
                                <div
                                    className="login-logout"
                                    onClick={() => signOut()}
                                >
                                    Sign Out
                                </div>
                            ) : (
                                <div
                                    className="login-logout"
                                    onClick={() => navigate('/login')}
                                >
                                    Sign In
                                </div>
                            )}
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>

            <div className="footer"></div>
        </div>
    );
}

export default Admin;
