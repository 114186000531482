import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { updateAttendanceEmail } from '../api/updateAttendanceEmail.js';

function EmailResponse() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const participant = searchParams.get('participant');
    const session = searchParams.get('session');
    const event = searchParams.get('event');
    const response = searchParams.get('response');

    const updateAttendance = async (e) => {
        await updateAttendanceEmail(participant, session, response);
        navigate('/session/event/' + event);
    };

    updateAttendance();

    return (
        <div className="App">
            {response === 'yes' ? (
                <h1>Thank you for confirming your attendance</h1>
            ) : response === 'no' ? (
                <h1>Thank you for declining the invitation</h1>
            ) : (
                <h1>Invalid response</h1>
            )}
            {participant && session && response && (
                <div>
                    <p>Participant: {participant}</p>
                    <p>Session: {session}</p>
                    <p>Response: {response}</p>
                </div>
            )}
        </div>
    );
}

export default EmailResponse;
